<template>
<v-container id="data-tables" tag="section">
  <base-material-card color="indigo" icon="mdi-book-open-page-variant-outline" inline class="px-5 py-3">
    <template v-slot:after-heading>
      <div class="display-2 font-weight-light">
        Manage Marketing Materials
      </div>
    </template>
    <v-data-table :headers="headers" :items="items" :disable-pagination="true" hide-default-footer item-key="uniqueID" sort-desc :loading="loading">
      <template v-slot:top>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-select :items="pageOptions" v-model="rowsPerPage" append-icon="mdi-chevron-down" class="ml-auto" label="Rows per Page" hide-details @change="filterTable">
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="text-sm-center">
            <v-dialog v-model="addMarketingResource" :retain-focus="false" max-width="600px" scrollable>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on" class="mt-4 mr-0">
                  Add Marketing Resource
                </v-btn>
              </template>
              <v-card :loading="cardLoading">
                <v-card-title>
                  <span class="headline">Add Marketing Resource</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="marketingName" label="Item Title*" :rules="marketingNameRules" required></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" v-on:click.stop="addMarketingResource = false">Nevermind</v-btn>
                  <v-btn :disabled="!marketingName" color="green darken-1" v-on:click.stop="createResource">Create</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip v-if="item.published === null" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" @click.stop="publishResourceConfirm(item)" v-bind="attrs" v-on="on" color="success">
              mdi-arrow-up-bold-circle
            </v-icon>
          </template>
          <span>Publish</span>
        </v-tooltip>
        <v-tooltip v-if="item.published !== null" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" @click.stop="unpublishResourceConfirm(item)" v-bind="attrs" v-on="on" color="error">
              mdi-arrow-down-bold-circle
            </v-icon>
          </template>
          <span>Unpublish</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-2" @click.stop="editResource(item)" v-bind="attrs" v-on="on" color="success">
              mdi-pencil
            </v-icon>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small @click.stop="deleteConfirm(item)" color="error" v-bind="attrs" v-on="on">
              mdi-delete
            </v-icon>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <h2>No items found in the Marketing Catalogue</h2>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-btn small rounded :disabled="(prevCursor === undefined || prevCursor === '') || loading === true || items === []" color="blue darken-1" v-on:click="previousPage()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn small rounded :disabled="(nextCursor === undefined || nextCursor === '') || loading === true || items === []" color="blue darken-1" v-on:click="nextPage()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
    <v-dialog :retain-focus="false" v-model="deleteConfirmDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Are you sure?</v-card-title>
        <v-card-text>
          This resource will be deleted, and unpublished. <br> It can be recovered later by contacting a Frog Administrator
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" v-on:click.stop="deleteConfirmDialog = false">
            No, don't delete
          </v-btn>
          <v-btn color="red darken-1" v-on:click.stop="deleteItem">
            Yes, delete resource
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :retain-focus="false" v-model="publishConfirmDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Are you sure?</v-card-title>
        <v-card-text>
          This resource will be published, and available immediately on public sites. <br> It can be unpublished or deleted at any point.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" v-on:click.stop="publishConfirmDialog = false">
            No, don't publish
          </v-btn>
          <v-btn color="green darken-1" v-on:click.stop="publishResource">
            Yes, publish resource
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :retain-focus="false" v-model="unpublishConfirmDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Are you sure?</v-card-title>
        <v-card-text>
          This resource will be unpublished, and immediately removed from public sites. <br> It can be re - published or deleted at any point.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" v-on:click.stop="unpublishConfirmDialog = false">
            No, don't unpublish
          </v-btn>
          <v-btn color="red darken-1" v-on:click.stop="unpublishResource">
            Yes, unpublish resource
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :retain-focus="false" v-model="editDialog" max-width="1000">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Resource</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-text-field v-model="editResourceObject.name" label="Resource Title*" :rules="marketingNameRules" required></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-switch inset v-model="editResourceObject.publishStatus" :label="`Published: ${editResourceObject.publishStatus.toString()}`"></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea outlined :rules="summaryRules" max-length="300" :counter="300" v-model="editResourceObject.summary" label="Summary"></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea outlined :rules="contentRules" max-length="1200" :counter="1200" v-model="editResourceObject.content" label="Content"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <h3>Links</h3>
          <v-row>
            <v-btn
              class="mx-2"
              small
              fab
              color="indigo"
              @click="addLink = !addLink"
            >
              <v-icon v-if="!addLink" dark>
                mdi-plus
              </v-icon>
              <v-icon v-if="addLink" dark>
                mdi-minus
              </v-icon>
            </v-btn>
            <v-col cols="12" class="text-center" v-show="addLink">
              <v-row>
                <v-col cols="12" md="4">
                  <v-select :items="linkTypeOptions" v-model="newLink.type"></v-select>
                </v-col>
                <v-col cols="12" md="8" v-if="newLink.type !== 3" >
                  <v-text-field v-model="newLink.content" label="Enter your link" hint="If adding a vimeo video, please use Vimeo ID" persistent-hint></v-text-field>
                </v-col>
                <v-col cols="12" md="8" v-if="newLink.type === 3">
                  <v-row>
                    <v-col cols="12" md="6" sm="6">
                      <v-radio-group row v-model="radioBtn">
                        <v-radio label="Upload" value="upload"></v-radio>
                        <v-radio label="Link" value="link"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
                <v-row v-if="newLink.type === 3">
                  <v-col cols="12" v-show="radioBtn === 'link'">
                    <v-text-field v-model="newLink.content" label="External Link" ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-show="radioBtn === 'upload'">
                    <fileUpload @imageUploaded="emitFile" :obj="file" fieldName="thumbnail" :directory="fileBucket" fileType="all" label="Select a File" />
                  </v-col>
                </v-row>
                <v-col cols="12">
                  <v-textarea :rules="[linkDesc.length]" outlined :max-length="50" :counter="50" v-model="newLink.description" label="Link Description"></v-textarea>
                </v-col>
              </v-row>
              <v-btn
                class="mx-2"
                color="success"
                @click="addNewLink()"
              >
                Add Link
              </v-btn>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-container fluid>
                <v-row dense>
                  <v-col
                    v-for="(link, index) in editResourceObject.links"
                    :key="index"
                    :cols="6"
                  >
                    <v-card
                      class="mx-auto"
                      max-width="400"
                    >

                      <v-avatar
                        class="ma-3"
                        size="125"
                      >
                        <v-icon large v-if="link.type === 1 || link.type === 2">mdi-link-variant</v-icon>
                        <v-icon large v-if="link.type === 3">mdi-file-document</v-icon>
                        <v-icon large v-if="link.type === 4">mdi-vimeo</v-icon>
                        <v-icon large v-if="link.type === 5">mdi-youtube</v-icon>
                      </v-avatar>

                      <v-card-subtitle class="pb-0">
                        {{link.description}}
                      </v-card-subtitle>

                      <v-card-text class="text--primary">
                        <div>{{link.content.substring(0, 35) + '...'}}</div>
                      </v-card-text>

                      <v-card-actions>
                        <v-btn
                          color="red"
                          text
                          @click="deleteLink(index)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-card-actions>

                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <h3>Resource Image</h3>
          <v-row>
            <v-col v-if="editResourceObject.imageUrl" cols="12">
              <v-img :src="editResourceObject.imageUrl" width="368" height="150" contain></v-img>
            </v-col>
            <v-col cols="12">
              <fileUpload @imageUploaded="emitImage" :obj="image" fieldName="thumbnail" :imageUrl="editResourceObject.imageUrl" fileType="image/png, image/jpeg" :directory="imageBucket" label="Select a Image" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" v-on:click.stop="editDialog = false">Cancel</v-btn>
          <v-btn color="green darken-1" v-on:click.stop="updateResource()">Update Resource</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snack" top right :timeout="snackTimeout" :color="snackColor">
      <v-progress-circular v-if="snackColor === 'info'" color="white" indeterminate></v-progress-circular>
      {{ snackText }}
    </v-snackbar>
  </base-material-card>
</v-container>
</template>

<script>

import marketingManagement from '@/Services/Marketing/MarketManagement'
import createResource from '@/Services/Marketing/CreateResource'
import getResource from '@/Services/Marketing/ResourceDetailsAdmin'
import deleteResource from '@/Services/Marketing/DeleteResource'
import publishResource from '@/Services/Marketing/PublishResource'
import updateResource from '@/Services/Marketing/UpdateResource'
import fileUpload from '@/views/dashboard/component/fileUpload'

export default {
  components: {
    fileUpload
  },
  data: () => ({
    linkTypeOptions: [
      { value: null, text: 'Select Link Type' },
      { value: 1, text: 'External Website Link' },
      { value: 2, text: 'External Document Link' },
      { value: 3, text: 'Internal Document Link' },
      { value: 4, text: 'Internal Video Link' },
      { value: 5, text: 'External Video Link' }
    ],
    newLink: {
      type: null,
      content: '',
      description: ''
    },
    linkDesc: {
      length: v => (v && v.length <= 50) || 'Content must be less than 50 characters'
    },
    addLink: false,
    imageBucket: process.env.VUE_APP_AWSIMAGEBUCKETMARKETING,
    fileBucket: process.env.VUE_APP_AWSFILEBUCKETMARKETING,
    radioBtn: null,
    publishMarketID: null,
    unpublishMarketID: null,
    publishConfirmDialog: false,
    unpublishConfirmDialog: false,
    snack: false,
    snackColor: '',
    snackText: '',
    snackTimeout: null,
    nextCursor: null,
    prevCursor: null,
    rowsPerPage: 10,
    pageOptions: [5, 10, 20, 50, 100],
    confirmDialog: false,
    deleteConfirmDialog: false,
    deleteResourceID: null,
    loading: true,
    cardLoading: false,
    headers: [{
      text: 'ID',
      align: 'start',
      value: 'uniqueID'
    },
    {
      text: 'Name',
      value: 'name'
    },
    {
      text: 'Published Date',
      value: 'publishedDate'
    },
    {
      text: 'Last Edited',
      value: 'lastEditedDate'
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
    ],
    items: [],
    marketingName: '',
    marketingNameRules: [
      v => !!v || 'Name is required'
    ],
    summaryRules: [
      v => (v && (v.length >= 1 && v.length <= 300)) || 'Summary must be less than 300 characters'
    ],
    contentRules: [
      v => (v && (v.length >= 1 && v.length <= 1200)) || 'Content must be less than 1200 characters'
    ],
    errors: [],
    addMarketingResource: false,
    editDialog: false,
    editResourceObject: {
      name: '',
      summary: '',
      content: '',
      links: [],
      imageUrl: '',
      publishStatus: ''
    },
    image: Object,
    file: Object
  }),
  created () {
    this.initialize()
  },
  methods: {
    emitImage (e) {
      this.editResourceObject.imageUrl = e
    },
    emitFile (e) {
      this.newLink.content = e
    },
    async initialize () {
      this.loading = true
      await marketingManagement.getItems(this.rowsPerPage)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'items', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.items = []
            this.snack = true
            this.snackColor = 'error'
            this.snackText = response.data.message
            this.snackTimeout = 5000
            this.loading = false
          }
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
    async previousPage () {
      this.loading = true
      await marketingManagement.getPreviousPage(this.rowsPerPage, this.prevCursor)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'items', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = response.data.message
            this.snackTimeout = 5000
          }
        })
        .catch(e => {
          this.errors.push(e)
          this.loading = false
        })
    },
    async nextPage () {
      this.loading = true
      await marketingManagement.getNextPage(this.rowsPerPage, this.nextCursor)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'items', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.loading = false
            this.snack = true
            this.snackColor = 'error'
            this.snackText = response.data.message
          }
        })
        .catch(e => {
          this.errors.push(e)
          this.loading = false
        })
    },
    async createResource () {
      this.cardLoading = true
      await createResource.createResource(this.marketingName)
        .then((response) => {
          if (response.status === 200) {
            this.cardLoading = false
            this.addMarketingResource = false
            this.editResource(response.data)
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to create resource'
            this.snackTimeout = 5000
          }
        })
    },
    async editResource (item) {
      this.editResourceObject = {
        name: '',
        summary: '',
        content: '',
        links: [],
        imageUrl: '',
        publishStatus: ''
      }
      await getResource.getDetails(item.uniqueID)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'editResourceObject', response.data)
            this.editResourceObject.id = item.uniqueID
            this.editDialog = true
          } else if (response.status === 204) {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'No Data Returned'
            this.snackTimeout = 5000
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Could not retrieve resource details'
            this.snackTimeout = 5000
          }
        })
    },
    async updateResource () {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Updating ...'
      this.snackTimeout = -1
      await updateResource.updateResource(this.editResourceObject.id, this.editResourceObject)
        .then((res) => {
          if (res.status === 200) {
            this.snackColor = 'success'
            this.snackText = 'Resource Updated Successfully'
            this.snackTimeout = 3000
            this.editDialog = false
            this.editResourceObject = {
              name: '',
              summary: '',
              content: '',
              links: [],
              imageUrl: '',
              publishStatus: ''
            }
            this.addLink = false
            this.initialize()
          } else {
            this.snackColor = 'error'
            this.snackText = res.data
            this.snackTimeout = 5000
            this.editDialog = false
            this.initialize()
          }
        })
    },
    deleteConfirm (item) {
      this.deleteResourceID = item.uniqueID
      this.deleteConfirmDialog = true
    },
    async deleteItem () {
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Deleting...'
      this.snackTimeout = -1
      await deleteResource.deleteResource(this.deleteResourceID)
        .then((response) => {
          if (response.status === 200) {
            this.deleteConfirmDialog = false
            this.initialize()
            this.snackColor = 'success'
            this.snackText = 'Resource was deleted successfully!'
            this.snackTimeout = 3000
          } else {
            this.deleteConfirmDialog = false
            this.snackColor = 'error'
            this.snackText = 'Failed to delete resource.'
            this.snackTimeout = 3000
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
    },
    publishResourceConfirm (resource) {
      this.publishMarketID = resource.uniqueID
      this.publishConfirmDialog = true
    },
    async publishResource () {
      const publishID = this.publishMarketID
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Publishing...'
      this.snackTimeout = -1
      await publishResource.publishResource(publishID)
        .then((response) => {
          if (response.status === 200) {
            this.publishConfirmDialog = false
            this.initialize()
            this.snackColor = 'success'
            this.snackText = 'Resource published successfully!'
            this.snackTimeout = 3000
          } else {
            const errorArray = []
            response.data.errors.forEach(element => {
              errorArray.push(element.errorMessage)
            })
            this.publishConfirmDialog = false
            this.initialize()
            this.snackColor = 'error'
            var i
            this.snackText = ''
            for (i = 0; i < errorArray.length; i++) {
              this.snackText += errorArray[i] + ', '
            }
            this.snackTimeout = 5000
          }
        })
    },
    unpublishResourceConfirm (resource) {
      this.unpublishMarketID = resource.uniqueID
      this.unpublishConfirmDialog = true
    },
    async unpublishResource () {
      const unpublishID = this.unpublishMarketID
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Unpublishing...'
      this.snackTimeout = -1
      await publishResource.unpublishResource(unpublishID)
        .then((response) => {
          if (response.status === 200) {
            this.unpublishConfirmDialog = false
            this.initialize()
            this.snackColor = 'success'
            this.snackText = 'Resource unpublished successfully!'
            this.snackTimeout = 3000
          } else {
            this.unpublishConfirmDialog = false
            this.initialize()
            this.snackColor = 'error'
            this.snackText = 'Resource could not be unpublished'
            this.snackTimeout = 5000
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
    },
    filterTable () {
      this.initialize()
    },
    addNewLink () {
      this.editResourceObject.links.push(this.newLink)
      this.newLink = {}
      this.addLink = false
    },
    deleteLink (index) {
      this.editResourceObject.links.splice(index, 1)
    }
  }
}
</script>
